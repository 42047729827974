import { trigger, style, animate, transition } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as L from 'leaflet';
@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
	localeMap: L.Map;
	@Output() locate = new EventEmitter();
	//@Input() setLocale;
	@Input() lng;
	@Input() lat;

	/*{
		
		if (locate & locate.lat && locate.lng) {
			this.markerListSalesOrder = new L.LayerGroup();
			this.markerListSalesOrder.addLayer(L.marker([locate.lat, locate.lng], {
				icon: this.getIcon
			}))
		}
	};*/





	/*get setLocale() {

		return this.locate;

	}*/
	//@Output() lng = new EventEmitter();
	/*@Input() controlLat: AbstractControl;
	@Input() controlLng: AbstractControl;
	isControlInvalidLat(controlLat): boolean {
		const result = controlLat.invalid && controlLat.touched;
		return result;
	}
	isControlInvalidLng(controlLng): boolean {
		const result = controlLng.invalid && controlLng.touched;
		return result;
	}*/
	map = null;
	/*get options() {
		return {
			layers: [
				L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18 })
			],
			zoom: 5,
			center: L.latLng(46.879966, -121.726909)
		}
	};*/

	options = {
		layers: [
			L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 20 })
		],
		zoom: 10,

		center: L.latLng(53.9140456, 27.557830810)
	};
	markerListSalesOrder;
	constructor(private changeDetection: ChangeDetectorRef) {

	}
	ngOnChanges(changes: SimpleChanges) {
		console.log(changes.lng);

		/*
		if (changes.lng && changes.lat) {
			this.markerListSalesOrder = new L.LayerGroup();
			this.markerListSalesOrder.addLayer(L.marker([changes.lat.currentValue, changes.lng.currentValue], {
				icon: this.getIcon
			}))
		} else if (changes.lat) {
			this.markerListSalesOrder = new L.LayerGroup();
			this.markerListSalesOrder.addLayer(L.marker([changes.lat.currentValue, changes.lng.previousValue], {
				icon: this.getIcon
			}))
		} else {
			this.markerListSalesOrder = new L.LayerGroup();
			this.markerListSalesOrder.addLayer(L.marker([changes.lat.previousValue, changes.lng.currentValue], {
				icon: this.getIcon
			}))
		}*/



		if (this.lat && this.lng) {
			this.markerListSalesOrder = new L.LayerGroup();
			this.markerListSalesOrder.addLayer(L.marker([this.lat, this.lng], {
				icon: this.getIcon
			}));

			//this.localeMap.setView(new L.LatLng(this.lat, this.lng), 8);
		}


	}
	changeCenter(map: L.Map) {
		if (this.lat && this.lng) {
			//map.setView(new L.LatLng(this.lat, this.lng), 8);
		}

	}
	getIcon = new L.Icon({
		// iconSize: [27, 27],
		iconAnchor: [13, 41],
		// popupAnchor:  [1, -24],
		iconUrl: 'assets/marker-icon.png'
	});

	//map.mouseEventToLatLng(ev.originalEvent)
	getLocate(event) {
		//console.log('Координаты' + event.latlng.lat + "   " + event.latlng.lng);
		this.markerListSalesOrder = new L.LayerGroup();
		this.markerListSalesOrder.addLayer(L.marker([event.latlng.lat, event.latlng.lng], {
			icon: this.getIcon
		}))
		this.locate.emit(event.latlng);




		//this.lat.emit(event.latlng.lat);
		//this.lng.emit(event.latlng.lng);

		//console.log(event);
	}

	onMapReady(map: L.Map) {

		this.localeMap = map;
		//console.log('Хуйняяя');

		this.markerListSalesOrder = new L.LayerGroup();
		/*this.markerListSalesOrder.addLayer(L.marker([46.879966, -121.726909], {
			icon: this.getIcon
		}))*/

		setTimeout(() => {
			map.invalidateSize(true);
		}, 0);

	}
	/*onMapReady(map: L.Map) {
		this.map = map;
	}*/


	ngOnInit(): void {






		//console.log(this.markerListSalesOrder);

		//window.dispatchEvent(new Event('resize'));
	}


}
