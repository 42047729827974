import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth.service';
import { AppSettingsService as _appSettings } from '../../app-settings.service';

@Injectable({
	providedIn: 'root'
})
export class ProductCategoriesService {

	constructor(private _http: HttpClient, private _auth: AuthService) { }

	public model = 'categories';

	create(data): Observable<CategoriesPostPutGetId> {
		const uri = _appSettings.API_ENDPOINT + this.model;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.post<CategoriesPostPutGetId>(uri, data, { headers: headers });
	}

	update(data): Observable<CategoriesPostPutGetId> {
		const uri = _appSettings.API_ENDPOINT + this.model;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.put<CategoriesPostPutGetId>(uri, data, { headers: headers });
	}

	list(data): Observable<CategoriesGet> {
		//NEED PARAMETERS IN URL
		const uri = _appSettings.API_ENDPOINT + this.model; // + to here
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.get<CategoriesGet>(uri, { headers: headers });
	}

	listRoots(data): Observable<CategoriesRoot[]> {
		//NEED PARAMETERS IN URL
		const uri = _appSettings.API_ENDPOINT + this.model + '/roots'; // + to here
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.get<CategoriesRoot[]>(uri, { headers: headers });
	}
	listRootsWhereID(id): Observable<CategoriesRoot[]> {
		//NEED PARAMETERS IN URL /rootsWhereId/:id
		const uri = _appSettings.API_ENDPOINT + this.model + '/rootsWhereId/' + id; // + to here
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.get<CategoriesRoot[]>(uri, { headers: headers });
	}

	shorts(data): Observable<Short[]> {
		//NEED PARAMETERS IN URL
		const uri = _appSettings.API_ENDPOINT + this.model + '/shorts'; // + to here
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.get<Short[]>(uri, { headers: headers });
	}

	get(id): Observable<CategoriesRoot[]> {
		const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.get<CategoriesRoot[]>(uri, { headers: headers });
	}
	getProdByCatName(name): Observable<CategoriesRoot[]> {
		const uri = _appSettings.API_ENDPOINT + 'products' + '/getProdByCatName/' + name;
		console.log('Данные в функцию' + uri);
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.get<CategoriesRoot[]>(uri, { headers: headers });
	}

	getField(data): Observable<CategoriesPostPutGetId> {
		const uri = _appSettings.API_ENDPOINT + this.model + '/fieldName';
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.post<CategoriesPostPutGetId>(uri, data, { headers: headers });
	}

	checkCatName(data): Observable<CategoriesPostPutGetId> {
		const uri = _appSettings.API_ENDPOINT + this.model + '/checkCatName';
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.post<CategoriesPostPutGetId>(uri, data, { headers: headers });
	}





	/*getFieldByName(name): Observable<any> {
		const uri = _appSettings.API_ENDPOINT + this.model + '/' + name;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())

		return this._http.post<any>(uri, { headers: headers });
	}*/






	delete(id): Observable<any> {
		const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.delete<any>(uri, { headers: headers });
	}


}


interface CategoriesGet {
	"content": CategoriesPostPutGetId[],
	"empty": boolean,
	"first": boolean,
	"last": boolean,
	"number": number,
	"numberOfElements": number,
	"pageable": {
		"offset": number,
		"pageNumber": number,
		"pageSize": number,
		"paged": boolean,
		"sort": {
			"empty": boolean,
			"sorted": boolean,
			"unsorted": boolean
		},
		"unpaged": boolean
	},
	"size": number,
	"sort": {
		"empty": boolean,
		"sorted": boolean,
		"unsorted": boolean
	},
	"totalElements": number,
	"totalPages": number
}

interface CategoriesPostPutGetId {
	flag: {};
	"is_ending": boolean;
	"photo": string;
	"description": string,
	"id"?: 0,
	"image": string,
	"name": string,
	"parentId"?: number,
	"parent"?: {
		id: number,
		name: string
	} | null,
	"productIds"?: number[]
}

interface CategoriesRoot {
	"children": CategoriesRoot[] | null,
	"description": string,
	"id": number,
	"image": string,
	"name": string
}

interface Short {
	id: number,
	name: string
}
