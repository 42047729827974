import {
	Component, OnInit, Input,
	ChangeDetectorRef, ElementRef, Renderer2
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { trigger, style, animate, transition } from '@angular/animations';


@Component({
	selector: 'form-textarea',
	templateUrl: './form-textarea.component.html',
	styleUrls: ['./form-textarea.component.scss'],
	animations: [
		trigger('enterAnimation', [
			transition(':enter', [
				style({ opacity: '0' }),
				animate('.1s', style({ opacity: '1' }))
			]),
			transition(':leave', [
				style({ opacity: '1' }),
				animate('.1s', style({ opacity: '0' }))
			])
		])
	]
})
export class FormTextareaComponent implements OnInit {
	@Input() label: string;
	@Input() control: AbstractControl;
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		elementRef: ElementRef,
		renderer: Renderer2
	) {
		//renderer.setStyle(elementRef.nativeElement, 'width', '100%');
		renderer.setStyle(elementRef.nativeElement, 'width', '100%');
	}
	isControlInvalid(control): boolean {
		const result = control.invalid && control.touched;
		return result;
	}
	ngOnInit() {
	}

}
