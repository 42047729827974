import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-my-bread',
	templateUrl: './my-bread.component.html',
	styleUrls: ['./my-bread.component.scss']
})
export class MyBreadComponent implements OnInit {
	breadcrumbs = [];
	constructor(private router: Router) { }

	ngOnInit(): void {
		let cUrl = '';
		let cName = '';
		console.log(this.router);
		let urls = this.router['browserUrlTree']['root']['children']['primary']['segments']
		for (let index = 0; index < urls.length; index++) {
			const element = urls[index];
			if (element == "shop") {
				cName = "Магазин";
			} else if (element == "category") {
				cName = "Категории";
			} else {
				var a = { "YO": "Ё", "_": " ", "I": "И", "TS": "Ц", "U": "У", "К": "K", "Е": "E", "N": "Н", "G": "Г", "SH": "Ш", "SCH": "Щ", "Z": "З", "H": "Х", "p": "Ъ", "yo": "ё", "i": "и", "ts": "ц", "u": "у", "k": "к", "е": "e", "n": "н", "g": "г", "sh": "ш", "sch": "щ", "z": "з", "h": "х", "pp": "ъ", "F": "Ф", "Ii": "Ы", "V": "В", "a": "а", "P": "П", "R": "Р", "О": "O", "L": "Л", "D": "Д", "ZH": "Ж", "E": "Э", "f": "ф", "ii": "ы", "v": "в", "а": "a", "pi": "п", "r": "р", "о": "o", "l": "л", "d": "д", "zh": "ж", "e": "э", "Ya": "Я", "CH": "Ч", "S": "С", "М": "M", "II": "И", "Т": "T", "pP": "Ь", "B": "Б", "YU": "Ю", "ya": "я", "ch": "ч", "s": "с", "m": "м", "iI": "й", "t": "т", "ppp": "ь", "b": "б", "yu": "ю" };
				//S_tovarami
				//Normalpnie_izobrazheniya
				//НoрмалЪниэ изoбразхэниyа
				function transliterate(word) {
					return word.split('').map(function (char) {
						return a[char] || char;
					}).join("");
				}
				cName = element.toString();
			}

			cUrl = cUrl + "/" + decodeURI(element)
			this.breadcrumbs.push({ "name": decodeURI(cName), "url": cUrl });

		}
		console.log(this.breadcrumbs);




	}

}
