import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';

@Component({
	selector: 'app-product-box-one',
	templateUrl: './product-box-one.component.html',
	styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {
	imageBase: string = this._appSettings.IMAGE_BASE;
	@Input() product;
	@Input() currency: any = this.productService.Currency; // Default Currency 
	@Input() thumbnail: boolean = false; // Default False 
	@Input() onHowerChangeImage: boolean = false; // Default False
	@Input() cartModal: boolean = false; // Default False
	@Input() loader: boolean = false;

	@ViewChild("quickView") QuickView: QuickViewComponent;
	@ViewChild("cartModal") CartModal: CartModalComponent;

	public ImageSrc: string

	constructor(private productService: ProductService, private _appSettings: AppSettingsService,) { }

	ngOnInit(): void {
		console.log('productproduct');
		console.log(this.product);
		if (this.loader) {
			setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
		}
	}

	// Get Product Color
	Color(variants) {
		const uniqColor = [];
		for (let i = 0; i < Object.keys(variants).length; i++) {
			if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
				uniqColor.push(variants[i].color)
			}
		}
		return uniqColor
	}

	// Change Variants
	ChangeVariants(color, product) {
		product.variants.map((item) => {
			if (item.color === color) {
				product.pictures.map((img) => {
					if (img.image_id === item.image_id) {
						this.ImageSrc = img.src;
					}
				})
			}
		})
	}

	// Change Variants Image
	ChangeVariantsImage(src) {
		this.ImageSrc = src;
	}

	addToCart(product: any) {
		this.productService.addToCart(product);
	}

	addToWishlist(product: any) {
		this.productService.addToWishlist(product);
	}

	addToCompare(product: any) {
		this.productService.addToCompare(product);
	}

}
