import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';

@Component({
	selector: 'app-product-box-three',
	templateUrl: './product-box-three.component.html',
	styleUrls: ['./product-box-three.component.scss']
})
export class ProductBoxThreeComponent implements OnInit {
	imageBase: string = this._appSettings.IMAGE_BASE;
	@Input() product;
	@Input() currency: any = this.productService.Currency; // Default Currency
	@Input() cartModal: boolean = false; // Default False

	@ViewChild("quickView") QuickView: QuickViewComponent;
	@ViewChild("cartModal") CartModal: CartModalComponent;

	constructor(private productService: ProductService, private _appSettings: AppSettingsService,) { }

	ngOnInit(): void {
		console.log('Продукт в box-3');
		console.log(this.product);
	}

	addToCart(product: any) {
		this.productService.addToCart(product);
	}

	addToWishlist(product: any) {
		this.productService.addToWishlist(product);
	}

	addToCompare(product: any) {
		this.productService.addToCompare(product);
	}

}
