<div class="input-item" [class.full-width]="fullWidth" [style.width]="width+'px'">
  <h3 *ngIf="!noLabel">{{ label }}</h3>
  <input *ngIf="!number" type="{{password ? (showPass ? 'text': 'password') : 'text'}}"
    [formControl]="control" [placeholder]="label" [required]="requiredField"
    [attr.disabled]="disabledField ? '' : null"
  />

  <input *ngIf="number" type="number"
    [formControl]="control" [placeholder]="label" [required]="requiredField"
    [attr.disabled]="disabledField ? '' : null"
  />
  <svg *ngIf="password" [class.show]="showPass" 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" height="24" 
    viewBox="0 0 24 24"
    (click)="showPass = !showPass"
  >
    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 
      6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 
      17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 
      5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 
      3-3-1.34-3-3-3z"/>
  </svg>
  <div class="error" [@enterAnimation] 
  *ngIf="isControlInvalid(control) && !noLabel">
    Неправильно {{ label | lowercase }}
  </div>
</div>
