<div class="flex-item" *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>
    <div class="front">
      <a [routerLink]="['/shop/product/left/sidebar/', product.id]">
			  <div class="img-inner">
				<img *ngIf="product.pictures!=null" [src]="imageBase + product.pictures[0]" class="img-fluid lazy-loading" alt="">
				 <img *ngIf="product.pictures==null" [src]="'assets/images/no-image.png'" class="img-fluid lazy-loading" alt="">
			  </div>
			 	
				
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/left/sidebar/', product.id]">
        <img [src]="ImageSrc ? ImageSrc : product.pictures[1]" class="img-fluid lazy-loading" alt="">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image" *ngFor="let image of product.pictures">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image)">
          <img [lazyLoad]="image">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Заявка" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Заявка" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/shop/product/left/sidebar/', product.id]">
        <h6>{{ product?.name | titlecase }}</h6>
      </a>
      <p>{{ product?.description }}</p>
      <h4>
       <span *ngIf="product?.type == 'Rent' ||product?.type == null " >{{ product?.recurring_price }} BYN / {{product?.recurring_price_every}}</span>
							<span *ngIf="product?.type == 'Sale'" >{{ product?.sale_price }} BYN </span>
					
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
      </h4>
		<!--
      <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul>
		-->
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

