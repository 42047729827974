

<div style="height: 300px;"
   leaflet [leafletOptions]="options"
	[leafletLayer]="markerListSalesOrder"
   (leafletMapReady)="onMapReady($event)"
	(leafletClick)="getLocate($event)"
	(leafletLayerAdd)="changeCenter($event)"
	>
	
</div>

