import { Component, OnInit, Input } from '@angular/core';
import { NewProductSlider } from '../../../data/slider';
import { Product } from '../../../classes/product';
import { ProductService } from '../../../services/product.service';
import { ProductsService } from 'src/app/shared/services/inventory/products/products.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';

@Component({
	selector: 'app-product-box-vertical-slider',
	templateUrl: './product-box-vertical-slider.component.html',
	styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {
	imageBase: string = this._appSettings.IMAGE_BASE;
	@Input() title: string = 'New Product'; // Default
	@Input() type: string = 'fashion'; //product Default Fashion
	@Input() product;
	public products;

	public NewProductSliderConfig: any = NewProductSlider;

	constructor(private _product: ProductsService, private _appSettings: AppSettingsService,) {

	}


	getList() {
		console.log('Получение продукта');
		this._product.list({}).subscribe(
			res => {
				console.log('Пролдукты');
				console.log(res.content);
				this.products = res.content;
				//console.log('Описание' + this.product.description);

			},
			err => {
				console.warn(err);

			}
		);
	}
	ngOnInit(): void {
		this.getList();
		console.log('Clider Prod');
		console.log(this.product);
	}

}
