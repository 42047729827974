<div class="main-navbar">
  <div id="mainnav">

    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>

    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-right" (click)="mainMenuToggle()">
          <span>Назад</span>
          <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
        </div>
      </li>

<!--
		title: 'Каталог', type: 'link', active: false, megaMenu: true, badge: false, path: '/shop/collection/left/sidebar\
		<li  [class.mega-menu]="true">

		</li>


		



		
-->



<!---
<li class="mega-menu categories-menu">
	<a routerLink="/shop/category">
              Каталог
             
              <span class="sub-arrow"></span>
            </a>


	<div class="mega-menu-container" >
<div class="container">
	
		<div class="col mega-box">
			<ul class="link-section nav-submenu">
				<li *ngFor="let cats of categories" class="menu-title">
					<h5>
						<a   routerLink="/shop/category/{{cats.name}}">{{cats.origin_name}}</a>
					</h5>
					<ul *ngIf="cats.children.length != 0" class="nav-sub-childmenu">
						<li *ngFor="let item of cats.children">
						<a routerLink="/shop/category/{{cats.name}}/{{item.name}}" >{{item.origin_name}}</a>

						<ul *ngIf="item.children.length != 0" class="nav-sub-childmenu lvl3">
						<li *ngFor="let item3lvl of item.children">
						<a routerLink="/shop/category/{{cats.name}}/{{item.name}}/{{item3lvl.name}}"  >{{item3lvl.origin_name}}</a>


						
							</li>
					</ul>

							</li>
					</ul>
			</li>
		</ul>
	</div>
</div>
	</div>
</li>
-->
 <li *ngFor="let cats of categories" class="menu-title">
					
						<a  class="nav-link"  routerLink="/shop/category/{{cats.name}}">{{cats.origin_name}}</a>
					
 </li>

      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">


        <!-- Sub -->
        <a href="javascript:void(0)" class="nav-link" *ngIf="menuItem.type === 'sub'" 
            (click)="toggletNavActive(menuItem)">
          {{ menuItem.title | translate }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- Link -->
        <a [routerLink]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"
          *ngIf="menuItem.type === 'link'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Tab Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <!-- 2nd Level Menu -->
        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
          <li *ngFor="let childrenItem of menuItem.children">
            <!-- Sub -->
            <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" 
                (click)="toggletNavActive(childrenItem)">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- Link -->
            <a [routerLink]="childrenItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
              *ngIf="childrenItem.type === 'link' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- External Link -->
            <a href="{{ childrenItem.path }}"
              *ngIf="childrenItem.type === 'extLink' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- External Tab Link -->
            <a href="{{ childrenItem.path }}" target="_blank"
              *ngIf="childrenItem.type === 'extTabLink' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>

            <!-- 3rd Level Menu -->
            <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
              <li *ngFor="let childrenSubItem of childrenItem.children">
                <!-- Link -->
                <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                  *ngIf="childrenSubItem.type === 'link' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Link -->
                <a href="{{ childrenSubItem.path }}"
                  *ngIf="childrenSubItem.type === 'extLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Tab Link -->
                <a href="{{ childrenSubItem.path }}" target="_blank"
                  *ngIf="childrenSubItem.type === 'extTabLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">
          <div class="container">
            <div class="row">

					
              <div class="col mega-box" *ngFor="let childrenItem of menuItem.children">
                <div class="link-section">
                  <div class="menu-title">
                    <h5>
                        {{ childrenItem.title | translate }}
                        <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                    </h5>
                  </div>
                  <div class="menu-content opensubmegamenu">
                    <ul *ngIf="childrenItem.children">
                      <li *ngFor="let childrenSubItem of childrenItem.children">
                        <!-- Sub -->
                          <a href="javascript:void(0)" *ngIf="childrenSubItem.type === 'sub'">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- Link -->
                          <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                            *ngIf="childrenSubItem.type === 'link' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- External Link -->
                          <a href="{{ childrenSubItem.path }}"
                            *ngIf="childrenSubItem.type === 'extLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- External Tab Link -->
                          <a href="{{ childrenSubItem.path }}" target="_blank"
                            *ngIf="childrenSubItem.type === 'extTabLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
								   
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
