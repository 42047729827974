import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
//import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { AuthService } from './shared/services/auth.service';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { SettingsComponent } from './shared/components/settings/settings.component';
import { PagesModule } from './pages/pages.module';
import { FormInputComponent } from './elements/form-input/form-input.component';
import { FormTextareaComponent } from './elements/form-textarea/form-textarea.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
	declarations: [
		AppComponent,
		ShopComponent,
		PagesComponent,
		ElementsComponent,

	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserAnimationsModule,
		HttpClientModule,
		NgbModule,
		LoadingBarHttpClientModule,
		LoadingBarRouterModule,

		ToastrModule.forRoot({
			timeOut: 3000,
			progressBar: false,
			enableHtml: true,
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		SharedModule,
		NgSelectModule,
		AppRoutingModule,
		//MatSlideToggleModule
		//PagesModule
	],
	providers: [
		AuthService,
		AuthGuardService
	],
	bootstrap: [AppComponent],

})
export class AppModule { }
