<div class="img-wrapper">
    <div class="ribbon" *ngIf="product"><span>new</span></div>
    <div class="front">
       <a [routerLink]="['/shop/product/left/sidebar/', product.id]">
			  <div class="img-inner">
				<img *ngIf="product.pictures!=null" [src]="imageBase + product.pictures[0]" class="img-fluid lazy-loading" alt="">
				 <img *ngIf="product.pictures==null" [src]="'assets/images/no-image.png'" class="img-fluid lazy-loading" alt="">
			  </div>

      </a>
    </div>
	 <!--
    <div class="cart-info cart-wrap">
        <a href="javascript:void(0)" (click)="addToWishlist(product)" title="Add to Wishlist">
        	<i class="fa fa-heart" aria-hidden="true"></i>
        </a>
        <button title="Заявка" (click)="CartModal.openModal(product)" *ngIf="cartModal">
        	<i class="ti-shopping-cart"></i> Заявка
        </button>
        <button title="Заявка" (click)="addToCart(product)" *ngIf="!cartModal">
        	<i class="ti-shopping-cart"></i> Заявка
        </button>
        <a href="javascript:void(0)" (click)="addToCompare(product)" title="Compare">
        	<i class="fa fa-refresh" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" class="mobile-quick-view" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
    </div>
-->
    <div class="cart-info cart-wrap">
       
        <button title="Заявка" [routerLink]="['/shop/product/left/sidebar/', product.id]" *ngIf="cartModal">
        	<i class="ti-shopping-cart"></i> Заявка
        </button>
        <button title="Заявка" [routerLink]="['/shop/product/left/sidebar/', product.id]" *ngIf="!cartModal">
        	<i class="ti-shopping-cart"></i> Заявка
        </button>
       
       
    </div>


    <div class="quick-view-part">
        <a href="javascript:void(0)" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
    </div>
</div>
<div class="product-info">
    <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/shop/product/left/sidebar/', product.id]">
    	<h6>{{ product?.name | titlecase }}</h6>
    </a>
    <h4 *ngIf="product?.type == 'Rent' ||product?.type == null " >{{ product?.recurring_price }} BYN / {{product?.recurring_price_every}}</h4>
							<h4 *ngIf="product?.type == 'Sale'" >{{ product?.sale_price }} BYN </h4>
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>