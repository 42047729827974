import { Component, OnInit, Input } from '@angular/core';
import { ProductCategoriesService } from '../../services/inventory/product-categories/product-categories.service';

@Component({
	selector: 'app-footer-one',
	templateUrl: './footer-one.component.html',
	styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {
	categories = [];
	@Input() class: string = 'footer-light' // Default class 
	@Input() themeLogo: string = 'assets/images/icon/logo.svg' // Default Logo
	@Input() newsletter: boolean = true; // Default True

	public today: number = Date.now();

	constructor(private _productCategories: ProductCategoriesService) { }

	getList() {

		this._productCategories.listRoots({}).subscribe(
			res => {
				this.categories = res;
				console.log(res);

			},
			err => {
				console.warn(err);

			}
		);
	}
	ngOnInit(): void {
		this.getList()
	}

}
