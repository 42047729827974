import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ProductCategoriesService } from 'src/app/shared/services/inventory/product-categories/product-categories.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
	categories = [];
	public menuItems: Menu[];

	openCat = false;

	constructor(private router: Router, public navServices: NavService,
		private _productCategories: ProductCategoriesService) {
		this.navServices.items.subscribe(menuItems => this.menuItems = menuItems);
		this.router.events.subscribe((event) => {
			this.navServices.mainMenuToggle = false;
		});
		//console.log(this.menuItems);
	}


	ngOnInit(): void {
		this.getList();
	}

	mainMenuToggle(): void {
		this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
	}

	// Click Toggle menu (Mobile)
	toggletNavActive(item) {
		item.active = !item.active;
	}


	getList() {

		this._productCategories.listRoots({}).subscribe(
			res => {
				this.categories = res;
				//console.log(res);

			},
			err => {
				console.warn(err);

			}
		);
	}
}
