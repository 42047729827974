<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" >
    <div class="container my-cont">
      <div class="row">
        <div class="col-lg-6">
          
        </div>
        <div class="col-lg-6 text-right">
          <ul class="header-dropdown">

<!--
            <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Сравнение</a>
            </li>
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Избранное</a>
            </li>
-->

            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> Мои аккаунт
              <ul class="onhover-show-div">
                <li *ngIf="!auth">
                  <a [routerLink]="['/pages/login']">
                    Войти
                  </a>
                </li>
                <li *ngIf="auth">
                  <a (click)="logout()">
                    Выйти
                  </a>
                </li>
					 <li *ngIf="auth">
                  <a [routerLink]="['/pages/dashboard']">
                    Профиль
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <!--<app-left-menu></app-left-menu>-->
            <div class="brand-logo">
              <a routerLink="/home/tools" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
