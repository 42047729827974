import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-header-one',
	templateUrl: './header-one.component.html',
	styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
	router: Router;
	@Input() class: string;
	@Input() themeLogo: string = 'assets/images/icon/logo.svg'; // Default Logo
	@Input() topbar: boolean = true; // Default True
	@Input() sticky: boolean = false; // Default false
	title = "topbar-title";
	auth = false;
	public stick: boolean = false;
	authService: AuthService;

	constructor(_router: Router, authService: AuthService) {
		this.router = _router;
		this.authService = authService;
		this.auth = this.authService.isAuthenticated();
	}

	ngOnInit(): void {


		//this.auth = this.authService.isAuthenticated();

		/*this.authService.isAuthenticated().subscribe({

			// this.form.patchValue({ restoreToken: restoreToken });
		});*/


	}
	logout(): void {
		localStorage.removeItem("token-customer");
		localStorage.removeItem("user-customer");
		this.router.navigate(['/pagewdwad']);
	}

	// @HostListener Decorator
	@HostListener("window:scroll", [])
	onWindowScroll() {
		let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		if (number >= 150 && window.innerWidth > 400) {
			this.stick = true;
		} else {
			this.stick = false;
		}
	}

}
