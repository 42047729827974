import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../auth.service';
import { AppSettingsService as _appSettings } from './../../app-settings.service';

@Injectable({
	providedIn: 'root'
})
export class ProductsService {

	constructor(private _http: HttpClient, private _auth: AuthService) { }

	public model = 'products';

	create(data): Observable<ProductsPost> {
		const uri = _appSettings.API_ENDPOINT + this.model;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.post<ProductsPost>(uri, data, { headers: headers });
	}

	update(data): Observable<ProductsPut> {
		const uri = _appSettings.API_ENDPOINT + this.model;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.put<ProductsPut>(uri, data, { headers: headers });
	}

	list(data): Observable<any> {
		// check and set params of pagination
		const size = 'size=' + (data.size || '');
		const page = 'page=' + (data.number || '');
		const search = 'search=' + (data.search || '');
		const params = `?${size}&${page}&${search}`;
		const uri = _appSettings.API_ENDPOINT + this.model + params;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.get<any>(uri, { headers: headers });
	}

	get(id): Observable<ProductGetId> {
		const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.get<ProductGetId>(uri, { headers: headers });
	}

	delete(id): Observable<any> {
		const uri = _appSettings.API_ENDPOINT + this.model + '/' + id;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.delete<any>(uri, { headers: headers });
	}

	shorts(data): Observable<Short[]> {
		const search = 'search=' + (data.search || '');
		const params = `?${search}`;
		const uri = _appSettings.API_ENDPOINT + this.model + '/short' + params;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.get<Short[]>(uri, { headers: headers });
	}

	status(data): Observable<any> {
		const id = data.id;
		const params = `?isActive=${data.isActive}`;
		const uri = _appSettings.API_ENDPOINT + this.model + '/change/status/' + id + params;
		const headers = new HttpHeaders()
			.set('X-Auth-Token', this._auth.getToken())
			.set('accept', '*/*');
		return this._http.put<any>(uri, data, { headers: headers });
	}

}

interface ProductsGet {
	"content": ProductGetId[],
	"empty": boolean,
	"first": boolean,
	"last": boolean,
	"number": number,
	"numberOfElements": number,
	"pageable": {
		"offset": number,
		"pageNumber": number,
		"pageSize": number,
		"paged": boolean,
		"sort": {
			"empty": boolean,
			"sorted": boolean,
			"unsorted": boolean
		},
		"unpaged": boolean
	},
	"size": number,
	"sort": {
		"empty": boolean,
		"sorted": boolean,
		"unsorted": boolean
	},
	"totalElements": number,
	"totalPages": number
}

interface ProductsPut {
	"active": boolean,
	"categoryIds": number[],
	"description": string,
	"discountId": number,
	"height": number,
	"id"?: number,
	"length": number,
	"name": string,
	"newItem": boolean,
	"number": string,
	"picture": string,
	"purchaseDate": string,
	"purchasePrice": number,
	"quantity": number,
	"recurringPrice": number,
	"recurringPriceEvery": string,
	"salePrice": number,
	"sellable": boolean,
	"taxable": boolean,
	"unitsW": string,
	"unitsWhl": string,
	"weight": number,
	"width": number
}

interface ProductsPost {
	"createProductItemDto": {
		"barcode": string,
		"description": string,
		"id"?: number,
		"productId": number,
		"purchaseDate": string,
		"purchaseOrderId": number,
		"purchasePrice": number,
		"rfid": string,
		"roomId": number,
		"status": string,
		"unitCost": number,
		"vendorId": number,
		"vendorNameArchive": string,
		"vendorProductNumber": string
	},
	"active": boolean,
	"categoryIds": number[],
	"description": string,
	"discountId": number,
	"height": number,
	"id"?: number,
	"length": number,
	"name": string,
	"newItem": boolean,
	"number": string,
	"picture": string[] | any[] | string,
	"purchaseDate": string,
	"purchasePrice": number,
	"quantity": number,
	"recurringPrice": number,
	"recurringPriceEvery": string,
	"salePrice": number,
	"sellable": boolean,
	"taxable": boolean,
	"unitsW": string,
	"unitsWhl": string,
	"weight": number,
	"width": number
}

interface ProductGetId {
	"active": boolean,
	"categories": {
		"description": string,
		"id": number,
		"image": string,
		"name": string,
		"parent": {
			"id": number,
			"name": string
		}
	}[],
	"description": string,
	"discount": {
		"id": number,
		"name": string,
		"rate": number
	},
	"height": number,
	"id": number,
	"length": number,
	"name": string,
	"newItem": boolean,
	"number": string,
	"pictures": string[],
	"productItems": {
		"barcode": string,
		"description": string,
		"id": number,
		"product": {
			"id": number,
			"name": string
		},
		"purchaseDate": string,
		"purchaseOrder": {
			"address": string,
			"allProductsTotalQuantity": number,
			"allProductsTotalSalePrice": number,
			"city": "string",
			"closedDate": string,
			"country": string,
			"facility": {
				"id": number,
				"name": string
			},
			"facilityNameArchive": string,
			"id": number,
			"issuedDate": string,
			"orderDate": string,
			"postalCode": string,
			"province": string,
			"purchaseOrder": string,
			"salesOrder": string,
			"state": string,
			"status": string,
			"vendor": {
				"id": number,
				"name": string
			},
			"vendorNameArchive": string
		},
		"purchasePrice": number,
		"rfid": string,
		"room": {
			"id": number,
			"name": string
		},
		"status": string,
		"unitCost": number,
		"vendor": {
			"id": number,
			"name": string
		},
		"vendorNameArchive": string,
		"vendorProductNumber": string
	}[],
	"purchaseDate": string,
	"purchasePrice": number,
	"quantity": number,
	"recurringPrice": number,
	"recurringPriceEvery": string,
	"salePrice": number,
	"sellable": boolean,
	"taxable": boolean,
	"unitsW": string,
	"unitsWhl": string,
	"weight": number,
	"width": number
}

interface Short {
	id: number,
	name: string,
	picture: string
}