<!-- side-bar single product slider start -->
<div class="theme-card">
    <!--<h5 class="title-border">{{ product.name }}</h5>-->
    <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
        <ng-container *ngFor="let product of products | slice:0:3 ">  
            <ng-template carouselSlide>
                <div>
                  <div class="media" *ngFor="let product of products | slice:0:3 ">
                    <a [routerLink]="['/shop/product/left/sidebar/', product.id]">
                     <div class="img-inner">
				<img *ngIf="product.pictures!=null" [src]="imageBase + product.pictures[0]" class="img-fluid lazy-loading" alt="">
				 <img *ngIf="product.pictures==null" [src]="'assets/images/no-image.png'" class="img-fluid lazy-loading" alt="">
			  </div>
                    </a>
                    <div class="media-body align-self-center">
                      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
                       <a [routerLink]="['/shop/product/left/sidebar/', product.id]">
                      <h6>{{ product.title | titlecase }}</h6></a>
                      <h4>
                        <!--{{ product?.price * productService.Currency?.price | discount:product | currency:productService.Currency?.currency:'symbol' }}-->
                        <del *ngIf="product?.discount">
                          <span class="money"> 
                          <!--  {{ product?.price * productService.Currency?.price | currency:productService.Currency?.currency:'symbol' }}-->
                          </span>
                        </del>
                      </h4>
                    </div>
                  </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div>
                  <div class="media" *ngFor="let product of products | slice:3:6 ">
                    <a [routerLink]="['/shop/product/left/sidebar/', product.prod_id]">
                     <div class="img-inner">
				<img *ngIf="product.pictures!=null" [src]="imageBase + product.pictures[0]" class="img-fluid lazy-loading" alt="">
				 <img *ngIf="product.pictures==null" [src]="'assets/images/no-image.png'" class="img-fluid lazy-loading" alt="">
			  </div>
                    </a>
                    <div class="media-body align-self-center">
                      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
                       <a [routerLink]="['/shop/product/left/sidebar/', product.prod_id]">
                      <h6>{{ product.title | titlecase }}</h6></a>
                      <h4>
                       <!-- {{ product?.price * productService.Currency?.price | discount:product | currency:productService.Currency?.currency:'symbol' }}--> 
                        <del *ngIf="product?.discount">
                          <span class="money"> 
                           <!-- {{ product?.price * productService.Currency?.price | currency:productService.Currency?.currency:'symbol' }}-->
                          </span>
                        </del>
                      </h4>
                    </div>
                  </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
  