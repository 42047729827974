import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettingsService as _appSettings } from '../services/app-settings.service';

@Injectable()
export class AuthService {

	constructor(private _http: HttpClient) { }

	public model = 'auth';

	login(data): Observable<any> {
		console.log('Функция логина');
		//const uri = _appSettings.API_ENDPOINT + this.model + '/user/signin-customer';
		const uri = _appSettings.API_ENDPOINT + this.model + '/user/signin-customer';
		return this._http.post<any>(uri, data);
	}

	change(data: ChangePass): Observable<any> {
		const uri = _appSettings.API_ENDPOINT + this.model + `/user/changePasswordCustomer?password=${data.password}&restoreToken=${data.restoreToken}&oldPass=${data.oldPass}`;

		const headers = new HttpHeaders()
			.set('X-Auth-Token', this.getToken())
			.set('accept', '*/*');

		return this._http.put<any>(uri, {});
		//	return this._http.put<any>(uri, data, { headers: headers });
	}



	drop(data): Observable<any> {
		const uri = _appSettings.API_ENDPOINT + this.model + `/user/dropPasswordCustomer?emails=${data.email}`;
		return this._http.post<any>(uri, data);
	}

	public isAuthenticated(): boolean {
		console.log('Функция проверки аунтификации');
		const token = localStorage.getItem('token-customer');
		//TODO
		//const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjMsImlhdCI6MTY2MjIxOTQ4MH0.JuYOp_j9gm_KzjbQmfMnNT6Mhdnu0zpByJQb_o-2EVQ";
		// Check whether the token is expired and return
		// true or false

		return !!token;
	}
	public getToken(): string {
		//TODO
		return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjMsImlhdCI6MTY2MjIxOTQ4MH0.JuYOp_j9gm_KzjbQmfMnNT6Mhdnu0zpByJQb_o-2EVQ"
		//return localStorage.getItem("token") || "¯＼_(ツ)_/¯ you don't have a token!";
	}
	public getCustomerToken(): string {
		//TODO
		//return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjMsImlhdCI6MTY2MjIxOTQ4MH0.JuYOp_j9gm_KzjbQmfMnNT6Mhdnu0zpByJQb_o-2EVQ"
		return localStorage.getItem("token-customer") || "¯＼_(ツ)_/¯ you don't have a token-customer!";
	}
}
interface User {
	accessToken: string,
	user: {
		id: number,
		lastName: string,
		firstName: string,
		email: string,
		description: any,
		addresses: any,
		roles: string[]
	}
}

interface ChangePass {
	oldPass: string,
	password: string,
	restoreToken: string
}